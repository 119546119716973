import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'

function Joinourteam() {
    return (
        <>
            {/* <!-- Header Start --> */}
            <div class="container-fluid bg-primary mb-5">
                <div
                    class="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: "400px" }}
                >
                    <h1 class="display-3 font-weight-bold text-white text-center">Join Our Team</h1>
                    <div class="d-inline-flex text-white">
                        <p class="m-0">
                            <Link class="text-white" to="/">
                                Home
                            </Link>
                        </p>
                        <p class="m-0 px-2">/</p>
                        <p class="m-0">Join Our Team</p>
                    </div>
                </div>
            </div>
            {/* <!-- Header End --> */}


            <section class="feature-area section-gap" id="service" style={{ textAlign: 'justify' }}>
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-8 pb-20 header-text">
                            <h2>We are Hiring! | Assignment Service Australia | Assignment Help Service</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-sm-12 col-xs-12" id="container-content-wrapper">
                            <p>If you have the zeal to work with one of the dominant assignments help service providers, then you can refer to the job details given below and apply for the posts that are currently vacant. Just pass the one step interview process and establish your professional career as an academic writer with us. Our Company strives for achieving excellence and we look for experienced and well-qualified experts who can render professional help. In case you want to join our team as a tutor, these are the key specifications required:</p>
                            <ul class="about-list">
                                <li>Post Graduate in any discipline</li>
                                <li>Extraordinary ability to comprehend the student’s instructions carefully</li>
                                <li>Advanced academic skills to write</li>
                                <li>Excellent researching skills</li>
                                <li>The accomplishment of assignments to meet the deadlines</li>
                                <li>Experience of all referencing styles (MLA, APA, IEEE, HAVARD, SCU HARVARD, etc.)</li>
                            </ul>

                            <div class="mt-3">
                                <h4>Jobs at Assignment Service Australia</h4>
                                <p class="mt-2">We are in search for aspiring and talented individuals, preferably current college or university students for the marketing activities of Assignment Service Australia. The role of the hired candidates will be to build and promote our brand by getting the needful students onboard and converting them into our clients.</p>
                            </div>

                            <div class="mt-3">
                                <h4>Job specifications</h4>
                                <ul class="about-list mt-1">
                                    <li>Educational Qualification: Existing college or university student</li>
                                    <li>Job Industry: Education</li>
                                    <li>Key Role: Brand Building and Promotion</li>
                                    <li>Salary: $1000 + incentives (Flexible)</li>
                                </ul>
                            </div>

                            <div class="mt-3">
                                <h4>Key Expertise</h4>
                                <ul class="about-list mt-1">
                                    <li>Go-getter, competence to work with minimal guidance and supervision</li>
                                    <li>Innovative, exuberant, creative</li>
                                    <li>Outcome driven</li>
                                    <li>Dynamic and flexible to changes</li>
                                    <li>Team Player</li>
                                </ul>
                            </div>

                            <div class="mt-3">
                                <h4>Job Details</h4>
                                <ul class="about-list mt-1">
                                    <li>To build brand by creating awareness of the Assignment Service Australia company</li>
                                    <li>To faucet promising and potential market in specific regions.</li>
                                    <li>To be responsible for promoting and creating buzz of company on both online and offline mediums by executing poignant initiatives for brand building (Online: Website blogs, blogging websites, social media platforms, campaigns on mail; Offline: brochure and pamphlet distribution, leafleting campaigns outdoor).</li>
                                    <li>To maintain company’s database and influencing marketing campaigns.</li>
                                    <li>To Coordinate with business to consumer events.</li>
                                </ul>
                            </div>
                            <div class="mt-3">
                                <h4>Required Aspirant Profile</h4>
                                <p class="mt-2">
                                    We prefer dynamic students from both UG and PG having the zeal and enthusiasm towards achieving set targets within the age group of 18-30 proficient with English speaking skills. The candidate must have the competence to creative, interesting and innovative initiatives for generating the demand of company both inbound as well as outbound and enhancing company’s growth. Specifically, the candidate must be:
                                </p>
                                <ul class="about-list">
                                    <li>Extremely creative, flexible and empirical</li>
                                    <li>Excellent with communication skills and candid response writing</li>
                                    <li>Filled with new and innovative ideas to achieve set targets</li>
                                </ul>
                            </div>
                            <p class="mt-4">
                                If you fulfill in meeting the above specifications, you can mail your resume at <b>assignmentserviceaustralia@gmail.com</b> and we will respond to you.
                            </p>
                        </div>
                        {/* <!-- Right Side --> */}
                        {/* <!-- Right Side --> */}
                        <div class="col-lg-4 sidebar">
                            <Samples_widget />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Joinourteam
