import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'

function How_it_works() {
  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h1 class="display-3 font-weight-bold text-white text-center">How it works</h1>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">How it works</p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}

      <section class="feature-area section-gap" id="service" style={{ textAlign: "justify" }}>
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-8 pb-40 header-text">
              <h2>How it works</h2>

            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-sm-12 col-xs-12" id="container-content-wrapper">
              <p>As we know, in today’s era of fast-paced academic pressures, students face difficulties in completing huge assignments simultaneously with specified timings. The craving for help and guidance is obvious. To ensure that precious time is not wasted in form filling, we have designed our website in a “student-friendly” manner to provide continuous service. The client does not need to follow the long manual to avail of our efficient services. Our process is quick and easy structured in just three steps.
              </p>
              <ul class="about-list mt-2">
                <li><b class="bold">Submit the assignment</b>
                  <div>
                    Simply, visit our website www.assignmentserviceaustralia.com and fill the submission form. The requirements and guidelines should be uploaded and you will soon receive a quote from us.
                  </div>
                </li>
                <li><b class="bold">Make Payment</b>
                  <div>
                    As soon as you receive the assignment quote, the payment transaction has to be processed through payment means mentioned in terms and conditions to confirm the order.
                  </div>
                </li>
                <li><b class="bold">Get a solution to the assignment</b>
                  <div>
                    The high quality, plagiarism-free and fully referenced assignment will be delivered to your account within the agreed deadline.
                  </div>
                </li>
              </ul>
              <p class="mt-4">
                Thus, it is so convenient to receive help from www.assignmentserviceaustralia.com straight at your place. In case you are not satisfied with the writing quality or the task needs some amendments, you can revert to our Team without hesitation. Although our expert writers hold years of experience and get the least requests for revisions yet, they provide 100% satisfaction in case you are not. We work 24/7 for you and you will get to know the level of expert services if you opt for the services from us. You can get in touch with our Support executives via e-mail or live chat and get a spontaneous response. For any help in assignments, assignmentserviceaustralia.com is the right avenue.
              </p>
            </div>
            {/* <!-- Right Side --> */}
            {/* <!-- Right Side --> */}
            <div class="col-lg-4 sidebar">
              <Samples_widget />
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default How_it_works
