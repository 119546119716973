import React,{useState,useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import './Samples_submenu.css'
import * as Constant from '../Constant'
import report from '../../assets/img/newHome/report.png'

function Samples_submenu() {
  const [demo,setDemos] = useState([]);
  const [subject,setSubject] = useState();
  const {id} = useParams();

  const getDemo = async () => {

    await fetch(Constant.Demos + '/' + id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data.data)
          setDemos(data.data)
          setSubject(data.data[0].subjectname)
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  const showSubDetails = (id) =>{
    console.log(id)
    window.location.href = '/Samples_widget/' + id;
  }

  useEffect(() => {
    getDemo();
  },[])

  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h1 class="display-3 font-weight-bold text-white">
            {subject}
          </h1>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">
              <Link class="text-white" to="/samples">
                Sample's
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">{subject}</p>
          </div>
        </div>
      </div>

      <section className="feature-area section-gap yellow_bg" id="service">
        <div
          className={`row mt-5 ${demo.length <= 3 ? 'justify-content-center' : 'justify-content-evenly'}`}
        >
          {demo.map((item, i) => (
            <div
              key={i}
              onClick={() => showSubDetails(item.id)}
              className="col-md-3 col-xl-3 col-sm-12 col-lg-3 col-xs-12"
            >
              <div className="fields_div sample_div">
                <div className="center_div">
                  <img src={report} alt="report" />
                </div>
                <p className="field_block_heading">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </section>


    </>
  )
}

export default Samples_submenu
