import React from 'react'
import './Research_service.css'
import research_banner from '../../../assets/img/newHome/research_banner.png'
import research_help_banner from '../../../assets/img/newHome/research_help_banner.png'
import authentic_work from '../../../assets/img/newHome/authentic_work.png'
import crafted_paper from '../../../assets/img/newHome/crafted_paper.png'
import gurrent_privacy from '../../../assets/img/newHome/gurrent_privacy.png'
import phd_expert from '../../../assets/img/newHome/phd_expert.png'
import step from '../../../assets/img/newHome/step.webp'

function Research_service() {
  return (
    <>
      <div className="research_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-xl-6">
              <h1 className="header_font" style={{ fontSize: 72 }}>Get the <span style={{ color: '#FEC00F' }}>best research  </span>help</h1>
              <p className="header_sub_font pb-4">We focus on providing the best research services while resolving all the odds to complete an efficient research paper. All the experts at ASA are Ph.D. holders or with an equivalent degree having enhanced experience in handling such projects. You could expect to get good marks by availing services from us.</p>
              <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                  <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                </svg></a>
            </div>
            <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12 col-xl-1"></div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={research_banner} alt='research_banner' className='about_banner_img' />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">12,958</p>
            <p className="request_text">Student requests</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,468</p>
            <p className="request_text">Assignments completed</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,378</p>
            <p className="request_text">Technical projects</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">1,273</p>
            <p className="request_text">In house professionals</p>
          </div>
        </div>
      </div>

      <p className='header_font text-center mt-4'>The reason students avail research help </p>
      <div className='container'>
        <p className='header_sub_font'>There are several reasons students may emphasise availing ofthe best research help. Our research team has witnessed several students facing crises and struggles in completing their work timely. The major reason is the ineffectiveness of students in writing quality research papers. The students who have a habit of writing short forms or informal writing could not result in producing an appropriate and high-quality research paper. Lastly, they seek for best research help to avail high quality so that they can get the best grades.
          <br></br><br></br>Working on any research paper is considered to be a huge task to be accomplished. Students sometimes feel less confident about selecting the research methodology as they are not very aware ofthe collection of primary or secondary data. Therefore, it results in getting help from research experts to gain enough knowledge about basic concepts of research.
          <br></br><br></br>With faster online information access, students are unable to identify valid data for their research papers. Due to a lack of various research skills, it becomes difficult for students to gather accurate data/information for their research work. Therefore, Assignment Service Australia becomes the pathway to achieve high-quality research work.
        </p>

        <p className='header_font'>What is the research paper service?</p>

        <p className='header_sub_font'>First of all, we would try to understand the significance of writing a research paper. Every student must have sufficient knowledge about the subject and fulfill all the aspects to complete the course. So, understanding the significance of getting the best research paper help means understanding the assignment, selecting of topic, conducting a preliminary research process, developing of thesis statement, creating of research outline, writing a research paper, proofreading, and editing in case of any flaws.
          <br></br><br></br>There are times when students are unable to understand the purpose of an assignment. Assignment Service Australia follows all the steps to facilitate in creation of an efficient research paper. So, when students prefer choosing us for the best research help, we focus on explaining the purpose of the assignment. Therefore, our research experts would facilitate in production of a high-quality research paper.
        </p>

        <p className='header_font'>Way to write the research paper</p>
        <p className='header_sub_font'>To score better marks, it is necessary to know an appropriate way to write best research paper. There are times when students look for various methods to write best research paper. Let Assignment Service Australia help you with best ways to write an appropriate research paper. Below are the steps to write an appropriate research paper:</p>

        <p className='header_sub_font'>•	Understanding the research paper objective<br></br>
          •	Selecting a good research topic<br></br>
          •	Conducting primary research<br></br>
          •	Developing a good thesis statement<br></br>
          •	Creating a good research outline<br></br>
          •	Making a research draft<br></br>
          •	Begin writing the research report<br></br>
          •	Using proper citations and referencing<br></br>
          •	Proofreading, editing and plagiarism checking<br></br>
        </p>

        <p className='header_sub_font'>It is necessary to have an appropriate research expert before planning to start working on the research paper. Connecting with us would enable you to solve all your issues and bring you closer to receiving good grades. Thus, do not hold back and take a step forward towards creating a perfect research paper for you. </p>

      </div>

      <p className="header_font text-center mt-4" style={{ fontSize: 55 }}>The way we help with the best research paper</p>

      <div className='row' style={{ padding: '10%' }}>
        <div className='col-md-8 col-sm-12 col-lg-8 col-xl-8 col-xs-12'>
          <div className='row'>
            <div className='col-md-6 col-lg-6 col-sm-12 col-xl-6 col-xs-12'>
              <div className="research_block">
                <div style={{ display: 'flex' }}>
                  <img src={authentic_work} alt='authentic_work' style={{ width: 65 }} />
                  <p className='block_head_text'>Analysis of the<br></br> research topic</p>
                </div>
                <div>
                  <p className='block_sub_text'>The experts of research papers emphasise understanding the essence of developing research paper along with an identification of starting the work for the final research paper. </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 col-sm-12 col-xl-6 col-xs-12'>
              <div className="research_block">
                <div style={{ display: 'flex' }}>
                  <img src={crafted_paper} alt='crafted_paper' style={{ width: 65 }} />
                  <p className='block_head_text'>Data<br></br> collection</p>
                </div>
                <div>
                  <p className='block_sub_text'>Another step is based on gathering all the necessary information to create an efficient research paper. Our best research experts focus on doing the same and focus on ensuring a piece of accurate information for the research paper. </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-6 col-sm-12 col-xl-6 col-xs-12'>
              <div className="research_block">
                <div style={{ display: 'flex' }}>
                  <img src={gurrent_privacy} alt='gurrent_privacy' style={{ width: 65 }} />
                  <p className='block_head_text'>Writing<br></br> research paper</p>
                </div>
                <div>
                  <p className='block_sub_text'>Like various other assignments, the research paper also emphasise following an appropriate format for the accomplishment of better grades. Once you ask for research help from Assignment Service Australia, you can be sure of getting the best grades while meeting all university guidelines. </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 col-sm-12 col-xl-6 col-xs-12'>
              <div className="research_block">
                <div style={{ display: 'flex' }}>
                  <img src={phd_expert} alt='phd_expert' style={{ width: 65 }} />
                  <p className='block_head_text'>Proofreading<br></br> and editing </p>
                </div>
                <div>
                  <p className='block_sub_text'>Our ASA experts not only facilitate in deliveryof an efficient research paper but also ensure in proofreading and editing of the document to receive better grades. </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12'>
          <img src={research_help_banner} alt='research_help_banner' className='about_banner_img' />
        </div>
      </div>

      <div className="process_sec ">
        <p className="grades pt-5">Easy<span style={{ color: '#fff' }}>&nbsp;3-step</span>Process</p>
        <div className="container">
          <div className="row" style={{ marginTop: 100, paddingBottom: 100 }}>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3 adjust">
              <div>
                <p className="step_text">Contact Us</p>
                <p className="step_sub_text">Contact us via the button below.</p>
              </div>
              <div>
                <p className="step_text">Get the experts</p>
                <p className="step_sub_text">Our team will review the form, get a expert for your assignment.</p>
              </div>
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-4 col-sm-12 col-lg-4 col-xs-12 col-xl-4" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <img src={step} alt='step' className='step_img' />
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
              <div>
                <p className="step_text">Fill the form</p>
                <p className="step_sub_text">Fill the form and message us your requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Research_service
