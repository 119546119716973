import React, { Component } from 'react';

export class Order_now extends Component {

  render() {
    return (
      <>
          <div className="container">
                    <h1 className="pb-20 text-center">Order Now</h1>
                    <form
                      acceptCharset="UTF-8"
                      className="form-area1 contact-form1 text-right1"
                      encType="multipart/form-data"
                    >
                      <div className="row flex-row d-flex">
                        <div className="col-lg-4 col-md-6">
                          <input
                            id="name"
                            name="name"
                            placeholder="Enter your name"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Enter your name'"
                            className="common-input form-control"
                            type="text"
                            defaultValue=""
                          />
                          <span className="error" id="name_error" />
                          <input
                            id="email"
                            name="email"
                            placeholder="Enter email address"
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Enter email address'"
                            className="common-input mt-3 mb-3 form-control"
                            type="email"
                            defaultValue=""
                          />
                          <span className="error" id="email_error" />
                          <input
                            type="text"
                            id="subject_id"
                            name="subject_id"
                            className="form-control common-input mt-3 ui-autocomplete-input"
                            placeholder="Enter Subject"
                            autoComplete="off"
                          />
                          <input
                            type="hidden"
                            id="subjectValue"
                            name="subjectValue"
                            readOnly=""
                          />
                         
              
                       <div><input placeholder="Enter Captcha Value"  className="form-control common-input mt-3 ui-autocomplete-input" id="user_captcha_input" name="user_captcha_input" type="text"></input></div>
                
                        </div>
                        <div className="col-lg-8 col-md-6 mt-3 mt-lg-0 areasize">
                          <textarea
                            className="form-control mb-2"
                            name="message"
                            rows="4"
                            placeholder="Message"
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Messege'"
                            defaultValue={""}
                          />

                          <input
                            type="submit"
                            className="btn btn-primary mt-2 py-2 px-4"
                            defaultValue="Order Now"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
      </>
    )
  }
}

export default Order_now
