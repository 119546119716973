import React from 'react'
import './Proofreading_service.css'
import prf_banner from '../../../assets/img/newHome/prf_banner.png'
import step from '../../../assets/img/newHome/step.webp'

function Proofreading_service() {
  return (
    <>
      <div className="prf_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-xl-6" style={{ paddingTop: 50 }}>
              <h1 className="header_font" style={{ fontSize: 72, color: '#FEC00F' }}>Editing & Proofreading <span style={{ color: '#000' }}>Service</span></h1>
              <p className="header_sub_font pb-4" >We provide the best editing & proofreading services for scientific articles, exhibitions, sites, newspaper articles and many more requests.</p>
              <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                  <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                </svg></a>
            </div>
            <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12 col-xl-1"></div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={prf_banner} className='about_banner_img' alt='prf_banner'/>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">12,958</p>
            <p className="request_text">Student requests</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,468</p>
            <p className="request_text">Assignments completed</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,378</p>
            <p className="request_text">Technical projects</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">1,273</p>
            <p className="request_text">In house professionals</p>
          </div>
        </div>
      </div>

      <p className="grades mt-5" >Best <span style={{ color: '#FEC00F' }}>Proof Reading </span>Service</p>
      <div className='row' style={{ marginLeft: '10%', marginRight: '10%' }}>
        <p className="essay_text mt-3">Assignment Service Australia offers Australian proofreading services to teachers, specialists, students and business administrators. The goal of Assignment Service Australia is to meet every need of business officers and students. We provide the best editing & proofreading services in Australia for scientific articles, exhibitions, sites, newspaper articles and requests for recommendations in various flows. Students can get assignment proofreading services for their homework on a variety of topics, for example, humanities, software engineering, sociology, etc. Our costs are set at a constant level and value continues as same for any complexity.</p>
        <p className="essay_text mt-1">Similarly, we have given proofreading services to many scientists and teachers, editing their complex substance before actual publication. Without editing a composite work, it may happen that the work will not be recognised in the light of numerous language and spelling mistakes. Our group of experts help in ensuring more and more efficient services in proofreading.</p>
      </div>

      <p className="grades mt-5 pb-2">Best Proof Reading Service in Australia</p>
      <hr style={{ backgroundColor: '#000', width: '78%' }}></hr>
      <p className="grades mt-4">Academic Proofreading Services</p>

      <div className='row' style={{ marginLeft: '10%', marginRight: '10%' }}>
        <p className="essay_text mt-3">Best Proof Reading Service in Australia - Preparing any scientific or academic article is a simple process but producing it to be sound and convincing content is a matter of concern. We make sure that the language used in the content is clear and appropriate. All tables and diagrams are used to display data visually. References are accurate and are in correct style. The support by QuickAssignment for assignment proofreading services can help you to polish all major aspects of a document.</p>
        <p className="essay_text mt-1">All your documents are proofread with the help of “track changes” function of Microsoft Word. This is a standard way to track changes in your document. We also provide proofreading services to all the LaTeX-based documents. We have a bank of proof-readers that are expert in different academic disciplines. QuickAssignment becomes very selective while hiring proof-readers. Our proof-readers are PhD holders with extensive experience. Along with higher qualification and experience, our proof-readers had also passed an entry test. The information related to our proof-readers is correct and accurate. We guarantee to never share your personal information with any third party.</p>
      </div>

      <div className="process_sec ">
        <p className="grades pt-5">Easy<span style={{ color: '#fff' }}>&nbsp;3-step</span>Process</p>
        <div className="container">
          <div className="row" style={{ marginTop: 100, paddingBottom: 100 }}>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3 adjust">
              <div>
                <p className="step_text">Contact Us</p>
                <p className="step_sub_text">Contact us via the button below.</p>
              </div>
              <div>
                <p className="step_text">Get the experts</p>
                <p className="step_sub_text">Our team will review the form, get a expert for your assignment.</p>
              </div>
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-4 col-sm-12 col-lg-4 col-xs-12 col-xl-4" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <img src={step} className='step_img' alt='step'/>
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
              <div>
                <p className="step_text">Fill the form</p>
                <p className="step_sub_text">Fill the form and message us your requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Proofreading_service
