import React from 'react'
import './CDR_service.css'
import cdr_banner from '../../../assets/img/newHome/cdr_banner.png'
import step from '../../../assets/img/newHome/step.webp'
import domain_specific from '../../../assets/img/newHome/domain_specific.png'
import top_quality from '../../../assets/img/newHome/top_quality.png'
import zero_page from '../../../assets/img/newHome/zero_page.png'
import best_help_left from '../../../assets/img/newHome/best_help_left.png'
import best_help_right from '../../../assets/img/newHome/best_help_right.png'


function CDR_service() {
  return (
    <>
      <div className="cdr_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-xl-6" style={{ paddingTop: 90 }}>
              <h1 className="header_font" style={{ fontSize: 72 }}>Online <span style={{ color: '#FEC00F' }}>CDR Assignment Help </span>for you</h1>
              <p className="header_sub_font pb-4" >Assignment Service Australia CDR(Competency Demonstration Report ) help</p>
              <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                  <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                </svg></a>
            </div>
            <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12 col-xl-1"></div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={cdr_banner} alt='cdr_banner' className='about_banner_img' />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">12,958</p>
            <p className="request_text">Student requests</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,468</p>
            <p className="request_text">Assignments completed</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,378</p>
            <p className="request_text">Technical projects</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">1,273</p>
            <p className="request_text">In house professionals</p>
          </div>
        </div>
      </div>

      <div className='research_help'>
        <p className="header_font text-center pt-3" style={{ fontSize: 65 }}>Best <span style={{ color: '#FEC00F' }}>Research </span>Help</p>

        <div className='container'>
          <div className='row' style={{ paddingTop: 10 }} >
            <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
              <div className="research_block">
                <div style={{ display: 'flex' }}>
                  <img src={domain_specific} alt='authentic_work' style={{ width: 72 }} />
                  <p className='block_head_text'>Professional<br></br> Engineer</p>
                </div>
                <div>
                  <p className='block_sub_text'>According to Engineer Australia, a professional engineer is an individual who has enough capability to effectively handle various engineering projects efficiently.  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
              <div className="research_block">
                <div style={{ display: 'flex' }}>
                  <img src={top_quality} alt='crafted_paper' style={{ width: 72 }} />
                  <p className='block_head_text'>Engineer<br></br>technologist</p>
                </div>
                <div>
                  <p className='block_sub_text'>Individuals having a Bachelor's degree in engineering from any recognised college could apply for migration to Australia. </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
              <div className="research_block">
                <div style={{ display: 'flex' }}>
                  <img src={zero_page} alt='crafted_paper' style={{ width: 72 }} />
                  <p className='block_head_text'>Engineer<br></br> associate</p>
                </div>
                <div>
                  <p className='block_sub_text'>Individuals having a bachelor's degree in engineering or any engineering diploma from any recognised institution could be identified as engineer associates. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-3 d-flex justify-content-center'>
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <img src={best_help_left} alt='best_help_left' className='about_banner_img' />
        </div>
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <div className="research_block">
            <div style={{ display: 'flex' }}>
              <img src={top_quality} alt='crafted_paper' style={{ width: 72 }} />
              <p className='block_head_text'>Engineer<br></br> manager </p>
            </div>
            <div>
              <p className='block_sub_text'>The individual having an Engineer diploma from any recognized university or institute is called an engineer manager. Such individuals emphasise working on different engineering projects. </p>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <div className="research_block">
            <div style={{ display: 'flex' }}>
              <img src={zero_page} alt='crafted_paper' style={{ width: 72 }} />
              <p className='block_head_text'>Major CDR <br></br> components</p>
            </div>
            <div>
              <p className='block_sub_text'>CDR is considered to be the most effective and significant document when deciding to apply for migration to Australia. Thus, it is necessary to understand different report elements when planning to write a CDR report. </p>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <img src={best_help_right} alt='best_help_right'/>
        </div>
      </div>

      <div className='container'>
        <div className='row' style={{ padding: 15 }}>
          <p className='header_sub_font'>Australia is considered to be the most efficient and preferred country for immigration by various engineers because of the different sets of opportunities available. For many years, the country has effectively invited various skilled individuals who could excel in their engineering field. However, Australia does not accept all individuals from this specific field as the Australian government has formulated various guidelines which focus on ensuring that only skilled individuals in the stated field would be allowed to be a part of Australia and find a job. Engineers Australia is considered to be an authority that aims to accept and reject proposals to shift to Australia and identify jobs as skilled engineers. It is necessary to provide proper proof of documentation to get acceptance from Engineers Australia. Later on, the proof of documentation is submitted to the Engineers Australian government body in the form of a CDR report. </p>

          <p className='header_font'>Reason for making CDR (Competency Demonstration Report ) report</p>

          <p className='header_sub_font'>CDR is considered to be the major part of the ASLM framework. ASLM stands for Australian skilled labor migration framework. It focuses on reflecting educational qualifications, learnings and all major aspects concerned with career advancements based on which various skills and competencies of an individual would be examined. Assignment Service Australia aims to provide enhanced care in writing CDR reports so that you can get the chance to move a step ahead to migrate to Australia without any issues.
            <br></br><br></br> For any engineer, it is necessary to have an enhanced knowledge. The engineer is responsible for handling and tackling all major challenges daily so that all project activities can be managed more smoothly. However, it has been estimated that the governmental authorities of Australia would not welcome any liability within their country. So, they emphasise approving such an engineer who has enough capability to create a safe working environment within an organisation.
          </p>


        </div>
      </div>

      <div className="process_sec ">
        <p className="grades pt-5">Easy<span style={{ color: '#fff' }}>&nbsp;3-step</span>Process</p>
        <div className="container">
          <div className="row" style={{ marginTop: 100, paddingBottom: 100 }}>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3 adjust">
              <div>
                <p className="step_text">Contact Us</p>
                <p className="step_sub_text">Contact us via the button below.</p>
              </div>
              <div>
                <p className="step_text">Get the experts</p>
                <p className="step_sub_text">Our team will review the form, get a expert for your assignment.</p>
              </div>
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-4 col-sm-12 col-lg-4 col-xs-12 col-xl-4" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <img src={step} className='step_img' alt='step'/>
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
              <div>
                <p className="step_text">Fill the form</p>
                <p className="step_sub_text">Fill the form and message us your requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CDR_service
