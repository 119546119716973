import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'

function Terms_conditions() {
    return (
        <>
            {/* <!-- Header Start --> */}
            <div class="container-fluid bg-primary mb-5">
                <div
                    class="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: "400px" }}
                >
                    <h1 class="display-3 font-weight-bold text-white">TERMS AND CONDITIONS</h1>
                    <div class="d-inline-flex text-white">
                        <p class="m-0">
                            <Link class="text-white" to="/">
                                Home
                            </Link>
                        </p>
                        <p class="m-0 px-2">/</p>
                        <p class="m-0">Terms And Conditions</p>
                    </div>
                </div>
            </div>
            {/* <!-- Header End --> */}


            <section class="feature-area section-gap" id="service" style={{ textAlign: "justify" }}>
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-8 pb-20 header-text">
                            <h1>TERMS OF USE</h1>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-sm-12 col-xs-12" id="container-content-wrapper">
                            <p>Before using our services, it is mandatory to read these terms carefully. For using the services of assignmentserviceaustralia.com, the person should be of legal age to undertake. The order will be considered confirmed the moment you place the order after reading, understanding and agreeing with all our terms and conditions. Improper usage is completely forbidden. It becomes your legal obligation to abide by all the terms when a request and/or payment is submitted.</p>

                            <div>
                                <h4>Version Of Our Terms :</h4>
                                <ul class="about-list mt-2">
                                    <li>“Website” implies assignmentserviceaustralia.com</li>
                                    <li>“Company”, “We” or “Our” means assignmentserviceaustralia.com website</li>
                                    <li>“Customer”, “You” or “Yours” refer to someone who submits, bids, executes and order, uploading the information and channelize the payment process on this website.</li>
                                    <li>“The Writer” is a person which is employed by the Company who provides writing and research services, in compliance with the agreement.</li>
                                    <li>“Message System” is a software that enables the continuous medium of imparting information between the client and the Writer or the Team Representative.</li>
                                    <li>“Order” means a request made electronically for a paid service for any writing service from a customer. A particular order must specify all the essential information and the scope of work regarding the writing service. It includes the requirements and specifications of sources to be used in the task.</li>
                                    <li>“Order status” implies the progress of the order towards completion.</li>
                                    <li>“Service” is the outcome of a particular Order, written by the professional writers and delivered to the consumer in compliance to their inquiry as a digital document.</li>
                                    <li>“Revision of Product” means a request made by the client to edit the final version of the product according to the requirement of the order, initially.</li>
                                    <li>“Quality Assurance Department” means the entity in the assignmentserviceaustralia.com, whose object is to evaluate and safeguard the quality of services rendered.</li>
                                    <li>“Wallet” refers to the personal account of the client that will store the Credits for the Customer. Funds will be added to the personal balance so as to compensate the price of their order at their own discretion.</li>
                                </ul>
                            </div>

                            <div class="mt-4">
                                <h4>ORDER REGISTRATION :</h4>
                                <ul class="about-list mt-2">
                                    <li>The order is placed by completing the form provided in the Application.</li>
                                    <li>This order form specifies the scope of work, order procedures and the terms of delivery. It is the responsibility of the customer to give the full and exact information in the order form.</li>
                                    <li>Our support can be contacted in case of any difficulties while registering any order.</li>
                                    <li>In the case of multiple accounts, they should be merged with the first account during the first purchase.</li>
                                </ul>
                            </div>

                            <div class="mt-4">
                                <h4>ORDER PAYMENT AND DISCOUNT :</h4>
                                <ul class="about-list mt-2">
                                    <li>When the order is placed, it means you agree to buy our services. The company will only initiate the order process only when the payment of the service is made and authorised.</li>
                                    <li>Orders can be made using debit cards, credit cards or any other means available by the company.</li>
                                    <li>The company offers discount programs to the customers using the discount code(s) provided in the order form in the discount section.</li>
                                    <li>After manually reviewing the order by our team, the Company may request additional payment or more time to complete the work.</li>
                                    <li>Payment can be made full-time or partially by the client but it is compulsory to pay half amount after its confirmation by our expert.</li>
                                    <li>1 credit means 1 US dollar and is stored in the personal account with no expiry. The credits stored may be used for the payment of future orders with the company.</li>
                                    <li>Customers can request changes in the work three times after its delivery to them. After the third time, an additional cost will be applicable.</li>
                                    <li>Customers should provide the necessary resource material to be used in the production process. If the specified resources are not provided timely and the writer has to locate them, additional charges can be incurred and must be paid
                                        before delivery in this case.</li>
                                    <li>The customer is free to track their order progress using the personal account. Moreover, they can contact the Support which is available 24/7, to get the order status.</li>
                                </ul>
                            </div>
                            <div class="mt-4">
                                <h4>ORDER DELIVERY :</h4>
                                <ul class="about-list mt-2">
                                    <li>The Company is responsible for the delivery of service and meeting the
                                        deadlines provided in the order.</li>
                                    <li>The company is not responsible for the inaccurate e-mail address, spam filters, internet outages or negligence of the client to communicate properly.</li>
                                    <li>It is the responsibility of the customer to download the service on time after being delivered by the company on the customer’s account mentioned in the order form.</li>
                                    <li>Reviewing each order carefully and getting the amendments made within the Seven days after the deadline is the responsibility of the client.</li>
                                </ul>
                            </div>
                            <div class="mt-4">
                                <h4>COMPANY’S RESPONSIBILITIES :</h4>
                                <ul class="about-list mt-2">
                                    <li>Our Company strictly abides by the copyright laws. It is the responsibility of the customer to abide by all the terms and conditions on our website.</li>
                                    <li>The Company follows a Zero- tolerance policy regarding fraud, plagiarism or academic dishonesty. We are not accountable for the illegal or unethical use of the Website content.</li>
                                    <li>Our privacy policy is referred to concern about the collection, storage and use of guests’ information. They ensure the protection of data, the Company undertakes all the processes through a secure online payment system.</li>

                                </ul>
                            </div>
                            <div class="mt-4">
                                <h4>Privacy Policy</h4>
                                <p class="mt-1">Look in Privacy Policy section.</p>
                            </div>
                            <div class="mt-4">
                                <h4>Revision and Refund Policy</h4>
                                <p class="mt-1">Look in Revision and Refund Policy section.</p>
                            </div>
                            <div class="mt-4">
                                <h4>Charges</h4>
                                <p class="mt-1">We reserve the right to collect registration or membership fees from the users to be able to use our services.</p>
                            </div>
                            <div class="mt-4">
                                <h4>Disassembly</h4>
                                <p class="mt-1">Considering the possibility that some of these conditions will not apply to the whole or to some extent, it will not affect the validity of the various terms of the contract.</p>
                            </div>
                            <div class="mt-4">
                                <h4>Amendments</h4>
                                <p class="mt-1">We reserve the exclusive right to modify the terms without notice. The latest version of the conditions will replace the previous versions.</p>
                            </div>
                            <div class="mt-4">
                                <h4>Termination</h4>
                                <p class="mt-1">We reserve the exclusive right to terminate all or part of the service contract at any time.</p>
                            </div>
                            <div class="mt-4">
                                <h4>Complete contract</h4>
                                <p class="mt-1">The Terms of Service provided in the last contract between the student and Assignment Service Australia will replace and terminate all previous contracts.</p>
                            </div>
                            <div class="mt-4">
                                <h4>Subscription</h4>
                                <p class="mt-1">The user agrees to receive an e-mail with a subscription about the updates of the Assignment Service Australia service or any other promotional offer.</p>
                            </div>

                            <div class="mt-4">
                                <h4>Revision and refund policy</h4>
                                <p class="mt-1"></p><h6>BELOW SIGNIFIES THE VARIOUS ASPECTS TO BE COVERED UNDER REVISION AND REFUND POLICY.</h6><p></p>
                                <ul class="about-list mt-2">
                                    <li>If the student decides to cancel his/her order before assigning that particular work to any writer that is working on it, another assignment can be booked easily without giving a single penny. However, in case the assignment has already been allocated to the writer then, it is necessary for the writer to continue with the current order. It is because of the reason that the writer who has started working on the particular assignment must get the compensation for the task.</li>
                                    <li>In case, if the student is charged twice by the executives of service provider and also received separate receipts from the processing system of the organisation. Then, the students should not hesitate in order to contact us in any manner. Students just have to forward all the receipts to Assignment Service Australia via email. Thus, it would enable to refund all the extra payments made by students to the company within a short span of time.</li>
                                    <li>We as Assignment Service Australia give our best in order to allocate the efficient writer to work on the assignments. However, there are very fewer chances that, when the writer is not available. In case, if it occurs, then we guarantee to deliver the assignment to students for free of cost.</li>
                                    <li>It is mandatory for students to ensure that all the proper details concerned with assignments that includes lecture notes and all other additional material should be provided while making an order for an assignment. In case, if, due to some reasons the assignment is not delivered to the student within deadline, then, the student is provided with a facility with increased content so that penalty for late submission would not affect the grade of the student.</li>
                                    <li>The policy for 100% satisfaction guarantees to ensure that the writers would keep on reworking on the particular assignment until the student or professor is not satisfied. The revisions for assignments would be done again and again till all the task requirements are met. It is necessary that the revisions for assignment must be done within the time limit of 31 days. After the time frame of 31 days, the assignment would not be accepted and thus, no refund would be provided to students in such cases.</li>
                                    <li>Along with this, Assignment Service Australia will never give any chance which results in fail grade in the student’s assignment. If in case, it may happen, then we guarantee to provide 50% refund to student from our wallet. It signifies that we follow wallet refund policy and proper proofs should be provided within the time limit of 31 days after completion of a student’s assignment. After particular time frame, any kind of request from the student’s side would not be acceptable. The major reason for following the refund policy up to 50% is that the writers take the money from Assignment Service Australia in advance and it is difficult to take the money back from the experts or writers. Thus, we guarantee to ensure efficient assignment quality and fire the writer instantly who is not providing good quality results.</li>
                                    <li>In order to book any assignment, the various three categories include distinction, credit and passing marks. We differentiate in providing numerous services to students and thus, charges are made as per the requirement of students. Although, there are very rare chances that such cases occur. In case, if it does, we guarantee to refund the amount to the wallet account of students. Along with this, proofs for scoring less marks should be provided within the time frame of 31 days from the actual date of assignment delivery. After 31 days, request from the student’s side would not be entertained in any manner.</li>
                                    <li>Other than the second clause, we do not provide any cash refund facility in availing the service. In case, if the reason is acceptable then we guarantee to deliver theassignments for free of cost instead of providing any refund to the students.</li>
                                </ul>
                            </div>

                            <div class="mt-4"><p><b class="bold">Disclaimer :</b>The situation would not be considered as valid enough, if it does not fall under all the above-stated categories. Also, no refund would be provided to the student.</p></div>

                            <div class="mt-4">
                                <h4>Privacy policy of Assignment Service Australia</h4>
                                <p class="mt-2">Protection of private information is one of the main concerns of the students who reach out to online websites for assignment help. Assignment Service Australia is committed to protect the privacy and security of the personal information of its customers. The main points of our privacy policy are listed below</p>
                                <ul class="about-list">
                                    <li>Assignment Service Australia does not share the personal details of the students with any third party under any circumstances.</li>
                                    <li>Assignment Service Australia uses a local server to host the information of the students and no information related to the students is hosted on the website.</li>
                                    <li>Paypal is responsible for handling the payment gateway and only PayPal has access to the credit card information of the users. Assignment Service Australia does not store any information related to the credit cards used by the students.</li>
                                    <li>The company has the right to share the information of its customers to the government or other parties at the request of the judiciary. Assignment Service Australia abides by the laws of all countries in which the company operates and provides its services. The data related to the customers is only shared after receiving the notice in written form from the court.</li>
                                    <li>Assignment Service Australia has the right to modify the privacy policy at any time without any warning. The changed privacy policy will be displayed on the Assignment Service Australia website.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 sidebar">
                            <Samples_widget />
                        </div>







                    </div>
                </div>
            </section>
        </>
    )
}

export default Terms_conditions
