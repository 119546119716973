import React from "react";
import essay_banner from '../../../assets/img/newHome/essay_banner.png'
import field_photo from '../../../assets/img/newHome/field_photo.png'
import submission from '../../../assets/img/newHome/submission.png'
import report from '../../../assets/img/newHome/report.png'
import approach from '../../../assets/img/newHome/approach.png'
import support from '../../../assets/img/newHome/support.png'
import annoymiti from '../../../assets/img/newHome/annoymiti.png'
import writers from '../../../assets/img/newHome/writers.png'

import './Essay_service.css'


function Essay_service() {
  return (
    <>

      <div className="essay_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-xl-6">
              <h1 className="header_font" style={{ fontSize: 66 }}>Choose the <span style={{ color: '#FEC00F' }}>Best Essay Writers </span>To Do Your Paper</h1>
              <p className="header_sub_font pb-4">We make sure that the writers we provide for essay writing are well-versed in tackling any given task of the student in a trustworthy manner.</p>
              <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                  <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                </svg></a>
            </div>
            <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12 col-xl-1"></div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={essay_banner} className="about_banner_img" alt="essay_banner"/>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">12,958</p>
            <p className="request_text">Student requests</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,468</p>
            <p className="request_text">Assignments completed</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,378</p>
            <p className="request_text">Technical projects</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">1,273</p>
            <p className="request_text">In house professionals</p>
          </div>
        </div>
      </div>

      <p className="grades mt-5" style={{ color: '#FEC00F' }}>Best Essay Writing<span style={{ color: '#000' }}>&nbsp;Help Online</span></p>

      <div className="row" style={{ marginLeft: '10%', marginRight: '10%' }}>
        <p className="essay_text mt-3">At Assignment Australia Servicewe provide essay writing help to students all across Australia. Our essay writing service is not only affordable but also of high quality. We have expert writers in different fields who have at least two or more years of experience in academic writing. Our writers are well versed in essay writing according to the needs of the students.</p>
        <p className="essay_text mt-4">We understand that some students might need urgent help with essay writing that is why our services are available for 24 hours, throughout the week. Our academic writers makes sure the assigned tasks are submitted on time as we know the criticality of timely submission. Also, there is no room for any delay of assigned tasks that is why we only take up the tasks which we are able to complete on time.We keep the track of the deadlines and make sure they are never missed.</p>
      </div>

      <p className="grades mt-5">We provide<span style={{ color: '#FEC00F' }}>&nbsp;essay writing help</span> In fields such as:</p>

      <div className="row" style={{ marginLeft: '10%', marginRight: '10%' }}>
        <ul className="mobile_menu">
          <li className="field_text" >Programming</li>
          <li className="field_text" >CDR for engineers</li>
          <li className="field_text" >MBA</li>
          <li className="field_text" >Computer Science</li>
          <li className="field_text" >Networking</li>
        </ul>

        <p className="essay_text">In essay writing the most common issue students have is with the format of the paper. They find it difficult to create documents in the required form. This is one of the major reasons most of the students work is refused. Writers at Assignment Australia Service are quite familiar with working with such formats and excel at it. We also give students enough time to check their assignment and report any change if needed.We make sure that the writers we provide for essay writing are well-versed in tackling any given task of the student in a trustworthy manner.</p>

        <div className="col-md-5 col-xl-5 col-sm-12 col-lg-5 col-xs-12">
          <img src={field_photo} className="about_banner_img" alt="field_photo" />
        </div>
        <div className="col-md-7 col-xl-7 col-sm-12 col-lg-7 col-xs-12">
          <p className="essay_text mt-5">Our writers are fully committed and highly motivated to help students with their essay writing tasks. We believe that students who are concerned about their studies should never have to struggle because of failing to complete their essay writing on time as it is a major part of their final assessment.Assignment Service Australia prioritise on making sure that each service is of the topmost quality so that students can achieve best results in terms of their grades.</p>
          <p className="essay_text mt-5">As we have highly qualified academic writers, our content is purely a product of our own work. We don’t believe in copying style or any sort of content from any external resources. To support the same, we also share plagiarism report with the students which is generated using turnitin.com. We believe in providing honest and trustworthy service to the students.</p>
        </div>
      </div>

      <div className="yellow_bg">
        <div className="row " style={{ padding: 50 }}>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12 ">
            <div className="fields_div">
              <div className="center_div">
                <img src={submission} alt="submission"/>
              </div>
              <p className="field_block_heading">Timely Submissions</p>
              <p className="field_block_text">We understand the importance of submitting the assignments on time without any delays. Our experts complete the assignments in a structured manner before time without affecting the quality of work that is to be done. After that, it goes under the quality check and is ready to be submitted on time.</p>
            </div>
          </div>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12">
            <div className="fields_div">
              <div className="center_div">
                <img src={report} alt="report"/>
              </div>
              <p className="field_block_heading">Originality Report</p>
              <p className="field_block_text">Our experts ensure to provide the best quality services to the students with minimal plagiarism. We thoroughly check the quality of the work done through authenticated software with evidence that is provided to the client.</p>
            </div>
          </div>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12">
            <div className="fields_div">
              <div className="center_div">
                <img src={approach} alt="approach"/>
              </div>
              <p className="field_block_heading">Personalized Approach</p>
              <p className="field_block_text">We personalize the work according to the client’s requirements which makes the client’s experience smooth. We use a unique approach to work out things in an efficient manner. In simple words, our work depends typically on the needs of the clients.</p>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: 50 }}>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12">
            <div className="fields_div">
              <div className="center_div">
                <img src={support} alt="support"/>
              </div>
              <p className="field_block_heading">24/7 Support</p>
              <p className="field_block_text">Our experts provide support around the clock. In this way, we try to provide more helpful assistance at every step to the students.</p>
            </div>
          </div>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12">
            <div className="fields_div">
              <div className="center_div">
                <img src={annoymiti} alt="annoymiti"/>
              </div>
              <p className="field_block_heading">Anonymity</p>
              <p className="field_block_text">We recognize the importance of protecting client information. As a result, we guarantee that the data will not be disclosed and will be kept completely confidential. We maintain the privacy of the ones who get the work done by us.</p>
            </div>
          </div>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12">
            <div className="fields_div">
              <div className="center_div">
                <img src={writers} alt="writers"/>
              </div>
              <p className="field_block_heading">Professional Writers</p>
              <p className="field_block_text">We have highly educated and qualified writers to help students. We have a dedicated team of quality checkers who check the quality of every assignment before handing it over to the student.</p>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Essay_service;
