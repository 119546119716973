import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import './Samples.css';
import * as Constant from '../Constant'
import report from '../../assets/img/newHome/report.png'

export default function Samples() {
  const [subject,setSubject] = useState([]);

  
  const getSubject = async () => {
    await fetch(Constant.Subjects, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setSubject(data.data)
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  const showSubMenu = async (id) =>{

    window.location.href = '/samples_submenu/' + id;
  }

  useEffect(() => {
    getSubject();
  },[])

  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h1 class="display-3 font-weight-bold text-white text-center">
            Assignment help and sample's review
          </h1>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">Sample's</p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}

      <section className="feature-area section-gap yellow_bg" id="service">
        <div
          className={`row mt-5 ${subject.length <= 3 ? 'justify-content-center' : 'justify-content-evenly'}`}
        >
          {subject.map((item, i) => (
            <div
              key={i}
              onClick={() => showSubMenu(item.id)}
              className="col-md-3 col-xl-3 col-sm-12 col-lg-3 col-xs-12"
            >
              <div className="fields_div sample_div">
                <div className="center_div">
                  <img src={report} alt="report" />
                </div>
                <p className="field_block_heading">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

    </>
  );
}
