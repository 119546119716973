import React from "react";
import { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import * as Constant from "../Constant";

export class Place_order extends Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      email: "",
      subject: "",
      due_date: "",
      resume: "",
      phone: "",

      fullNameError: null,
      emailError: null,
      subjectError: null,
      countryError: null,
      numberError: null,
      due_dateError: null,
      isLoading: false,
      Loading: true,

      captchaText: "",
      userInput: "",
      isCaptchaValid: false,
      captchError: null,

      num1: Math.floor(Math.random() * 10) + 1,
      num2: Math.floor(Math.random() * 10) + 1,
      answer: '',
      isCorret: ''
    };
  }


  generateNewNumbers = () => {
    this.setState({
      num1: Math.floor(Math.random() * 10) + 1,
      num2: Math.floor(Math.random() * 10) + 1,
      answer: '',
      isCorret: false
    })
  }


  validate = () => {
    let fullNameError = "";
    let emailError = "";
    let subjectError = "";
    let numberError = "";
    let due_dateError = "";
    let captchError = "";
    let expectedAnswer = this.state.num1 + this.state.num2;

    if (!this.state.fullName) {
      fullNameError = "Please enter full name.";
    }
    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Email cannot be empty.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid email address.";
      }
    }
    if (!this.state.subject) {
      subjectError = "Subject cannot be empty,";
    }
    if (!this.state.phone) {
      numberError = "Number cannot be empty.";
    }
    if (!this.state.due_date) {
      due_dateError = "Due date cannot be empty.";
    }

    if (!this.state.userInput) {
      captchError = "Please Enter Captcha Code"
    } else {
      if (parseInt(this.state.userInput, 10) !== expectedAnswer) {
        captchError = "Please Enter Valid Captcha"
      }
    }


    if (
      fullNameError ||
      emailError ||
      subjectError ||
      numberError ||
      due_dateError ||
      captchError
    ) {
      this.setState({
        fullNameError,
        emailError,
        subjectError,
        numberError,
        due_dateError,
        captchError
      });
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        fullNameError: null,
        emailError: null,
        subjectError: null,
        numberError: null,
        due_dateError: null,
        captchError: null,
      });
      this.onContact();
    }
  };

  onContact = async () => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append("fullname", this.state.fullName);
    formData.append("email", this.state.email);
    formData.append("subject", this.state.subject);
    formData.append("number", this.state.phone);
    formData.append("due_date", this.state.due_date);
    formData.append("resume", this.state.resume);

    await fetch(Constant.AssignmentUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            fullName: "",
            email: "",
            subject: "",
            number: "",
            due_date: "",
            resume: "",
          });
          toast.success("Your request submitted successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.resetFile();
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
        this.setState({ isLoading: false });
      });
  };
  resetFile() {
    const file = document.querySelector(".file");
    file.value = "";
  }

  render() {
    return (
      <>
        {/* <!-- Header Start --> */}
        <div class="container-fluid bg-primary mb-5">
          <div
            class="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: "400px" }}
          >
            <h1 class="display-3 font-weight-bold text-white">Order Now</h1>
            <div class="d-inline-flex text-white">
              <p class="m-0">
                <Link class="text-white" to="/">
                  Home
                </Link>
              </p>
              <p class="m-0 px-2">/</p>
              <p class="m-0">Order Now</p>
            </div>
          </div>
        </div>
        {/* <!-- Header End --> */}

        <section
          className="feature-area section-gap contact-page-area"
          id="service"
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 header-text text-center">
                <h1>Please submit order details to us</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-5 m-auto">

                <input
                  name="_token"
                  type="hidden"
                  defaultValue="Xkw3X5VW6NOjwZedqqa3T6A2WmYtL9oFzteDWCue"
                />
                <input
                  type="hidden"
                  name="request_from"
                  defaultValue="Place Order"
                />
                <div className="form-group row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Your name"
                      required="required"
                      value={this.state.fullName}
                      onChange={(event) =>
                        this.setState({
                          fullName: event.target.value,
                        })
                      }
                    />
                    {!!this.state.fullNameError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                        }}
                      >
                        {this.state.fullNameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email address"
                      required="required"
                      value={this.state.email}
                      onChange={(event) =>
                        this.setState({
                          email: event.target.value,
                        })
                      }
                    />
                    {!!this.state.emailError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                        }}
                      >
                        {this.state.emailError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <PhoneInput
                      class="phone"
                      country={"au"}
                      value={this.state.phone}
                      onChange={(phone) => this.setState({ phone })}
                    />
                    {!!this.state.numberError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                        }}
                      >
                        {this.state.numberError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      id="subject_search"
                      name="subject_search"
                      className="form-control ui-autocomplete-input"
                      placeholder="Enter Subject"
                      autoComplete="off"
                      required="required"
                      value={this.state.subject}
                      onChange={(event) =>
                        this.setState({
                          subject: event.target.value,
                        })
                      }
                    />
                    {!!this.state.subjectError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                        }}
                      >
                        {this.state.subjectError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <input
                      type="date"
                      id="date_of_birth"
                      name="date_of_birth"
                      className="form-control height"
                      value={this.state.due_date}
                      required
                      autoComplete="on"
                      min={new Date().toISOString().substr(0, 10)}
                      onChange={(event) =>
                        this.setState({
                          due_date: event.target.value,
                        })
                      }
                    />
                    {!!this.state.due_dateError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                        }}
                      >
                        {this.state.due_dateError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="">
                  <input
                    type="hidden"
                    name="attachment_id"
                    id="attachment_id"
                    defaultValue={1689148004}
                  />
                  <div className="form-group">
                    <div
                      id="uploads-widgets"
                      method="post"
                      action=""
                      className="dropzone-upload "
                    >
                      <div className="fallback">
                        <input
                          type="file"
                          class="file"
                          name="resume"
                          multiple="true"
                          onChange={(event) =>
                            this.setState({
                              resume: event.target.files[0],
                            })
                          }
                        />
                      </div>

                    </div>
                  </div>
                  <div className="form-group">
                    <strong>Allow file types</strong> : jpg, jpeg, png, gif,
                    doc, docx, xls, xlsx, csv, pdf, ppt, pptx, zip{" "}
                  </div>
                  <div className="col-md-12">
                    <div
                      className="table-responsive"
                      id="file_display_table1"
                      style={{ display: "none" }}
                    >
                      <div className="form-group">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th style={{ color: "white" }}>Action</th>
                              <th style={{ color: "white" }}>URL</th>
                            </tr>
                          </thead>
                          <tbody id="file_display1" />
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="col-md-12 ">
                    <div className="d-flex align-items-center">
                      <span className="equation"> {this.state.num1} + {this.state.num2} =</span>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.userInput}
                        style={{ width: 60, height: 35, margin: 3 }}
                        onChange={(e) => this.setState({ userInput: e.target.value })}
                      />
                    </div>
                    {!!this.state.captchError && (
                      <text style={{ color: "red", marginLeft: "10px" }}>
                        {this.state.captchError}
                      </text>
                    )}
                  </div>

                </div>

                <div className="form-group row">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      name="submit"
                      onClick={() => this.onSubmit()}
                      className={
                        this.state.isLoading
                          ? "btn btn-primary px-4 mx-auto pl-5 pr-5"
                          : "btn btn-primary px-4 mx-auto"
                      }
                    >
                      {this.state.isLoading ? (
                        <ClipLoader
                          type="TailSpin"
                          color="#FFFFFF"
                          height="25"
                          width="25"
                        />
                      ) : (
                        "submit"
                      )}
                    </button>
                    <ToastContainer />
                  </div>
                </div>

                {/* </form> */}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Place_order;
