import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'

export default function Faq() {
    return (
        <>
            {/* <!-- Header Start --> */}
            <div class="container-fluid bg-primary mb-5">
                <div
                    class="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: "400px" }}
                >
                    <h1 class="display-3 font-weight-bold text-white text-center">Frequently Asked Questions</h1>
                    <div class="d-inline-flex text-white">
                        <p class="m-0">
                            <Link class="text-white" to="/">
                                Home
                            </Link>
                        </p>
                        <p class="m-0 px-2">/</p>
                        <p class="m-0">FAQ</p>
                    </div>
                </div>
            </div>
            {/* <!-- Header End --> */}

            <section class="feature-area section-gap">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-8" id="container-content-wrapper">
                            <p>Just like many students, you too might have some queries prior to availing our services. We have answered a few queries from other students. See if these help you!</p>
                            <h3>Query About Assignment Service Australia</h3><br></br>
                            <div class="accordion" id="accordionExample">
                                <div class="card panel">
                                    <div class="card-header bg-light" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Why should I choose your essay writing service?
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" class="panel-collapse collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body  background">
                                            <div class="g-mb-70 justify-content-center row">
                                                <div class="col-lg-12">
                                                    <div class="terms">
                                                        <p style={{ textAlign: "justify" }}>Assignments service Australia offers the highest quality of essay and research paper help that too at affordable prices. We also offer 24*7 assistance, just in case you need last-minute tasks to be done or revisions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card panel">
                                    <div class="card-header bg-light" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                For what levels do you provide assignment solutions?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="panel-collapse  collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div class="card-body background">
                                            <div class="g-mb-70 justify-content-center row">
                                                <div class="col-lg-12">
                                                    <div class="terms">
                                                        <p style={{ textAlign: "justify" }}>We provide assignment solutions for all levels starting from primary, to high school, graduation, and post-graduation level as well.</p>                                      </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="card panel">
                                    <div class="card-header bg-light" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How do I know if the tutors are experts and are specialized as per the subjects?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="panel-collapse collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body background">
                                            <p style={{ textAlign: "justify" }}>You can communicate with the experts and have a live interaction with them during your assignment work.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card panel">
                                    <div class="card-header bg-light" id="headingFour">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                What should I do in case I am not satisfied with your work?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" class="panel-collapse collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                        <div class="card-body background">
                                            <p style={{ textAlign: "justify" }}>Just in case you are not satisfied with our expert's work, you can ask for unlimited revisions until you are satisfied.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card panel">
                                    <div class="card-header bg-light" id="headingFive">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                Will my assignment or details be shared?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" class="panel-collapse collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                        <div class="card-body background">
                                            <p style={{ textAlign: "justify" }}>Assignment service Australia offers a 100% guarantee concerning confidentiality. Our writers never share the consumer information with the third parties and so they remain private forever.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card panel">
                                    <div class="card-header bg-light" id="headingSeven">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                What if I receive plagiarized content for my assignment?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSeven" class="panel-collapse collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                        <div class="card-body background">
                                            <p style={{ textAlign: "justify" }}>Assignment service Australia gives 100% assurance that there will be no plagiarism in your assignment or essay paper. To provide the evidence we provide a plagiarism report along with the complete solution..</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!-- Right Side -->
        <!-- Right Side --> */}
                        <div class="col-lg-4 sidebar">

                            <Samples_widget />

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
