import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'
import blogimg from '../../assets/img/Best_essay_writing_Service.png'
import blogimg1 from '../../assets/img/write.png'
import blogimg2 from '../../assets/img/top-essay-writing-services.png'
import blogimg3 from '../../assets/img/img2.jpeg'
import blogimg4 from '../../assets/img/img33.jpg'
import blogimg5 from '../../assets/img/img44.jpg'
import blogimg6 from '../../assets/img/student.jpg'

function Blogs() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h1 class="display-3 font-weight-bold text-white">Blogs</h1>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">Blog</p>
          </div>
        </div>
      </div>

      <section className="blog-posts-area section-gap">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-8 post-list blog-post-list"
              id="container-content-wrapper"
            >
              <div className="single-post">
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg}
                      alt="blogimg"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">
                        HOW TO COMPLETE YOUR ASSIGNMENTS ON TIME?{" "}
                      </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    Managing studies, assignments, homework, and extracurricular
                    activities can be quite challenging and can lead to delays in the
                    completion of tasks.
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg1}
                      alt="blogimg1"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">
                        5 Lifesaving Academic Writing Tips Every Graduate Student
                        Should Know About!{" "}
                      </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    Students can make use of essay writing services and learn about
                    life-saving academic writing tips to save time, energy, and money.
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg}
                      alt="blogimg"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">
                        Essay Sample on Role Of Students in School{" "}
                      </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    Student life is the happiest and the most crucial period of a
                    person’s life. Student Life is not just about attending class and
                    completing
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg2}
                      alt="blogimg2"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">What is a Good Essay Writing Service </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    Professors may ask students for completing various complex
                    assignments, which would be highly frustrating in case learners
                    have limited skills and time.
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg}
                      alt="blogimg"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">
                        Reasons Why Essay Writing Services Are the Best At Helping
                        College Students{" "}
                      </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    Being a college student is nothing less than a challenge but at
                    the same time, it’s also rewarding. You get to create your career
                    path
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg6}
                      alt="blogimg6"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">Maslow"s Hierarchy of Needs </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    Maslow’s theory was formulated by Abraham Maslow in the year 1943.
                    He believed that each individual has a hierarchy of needs such as
                    physiological, safety, social (love and belongingness), esteem and
                    self-actualisation needs.
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg3}
                      alt="blogimg3"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">3 R's of waste management </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    The major cause of environmental degradation is the waste produced
                    by humans. The waste is mainly generated by commercials,
                    households, constructional organisations and street sweeping.
                    Also, waste occurs through farming or agricultural sector.
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg5}
                      alt="blogimg5"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">
                        What is MITM? How it can be performed and mitigated{" "}
                      </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    MITM (Man-in-the-middle) is a cyber-attack which allow a malicious
                    user to intercept communication between one user and another user.
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="single-heading">
                  <div>
                    <img
                      className="img-fluid"
                      src={blogimg4}
                      alt="blogimg4"
                    />
                  </div>
                  <div>
                    <a href="">
                      <h3 className="mt-3">
                        Are you facing errors while installing Window Server 2019 in a
                        virtual environment? Do not stress yourself. Here is a simple
                        solution for you.{" "}
                      </h3>
                    </a>
                  </div>
                  <p className="text-justify mt-2">
                    Microsoft has launched the latest version of window server that is
                    window server 2019. It is the part of the Windows NT family OS
                    (Operating system).
                  </p>
                  <div className="p-2 float-right">
                    <Link onClick={goToTop} to="/blogs_sub">
                      <button
                        type="button"
                        className="btn btn-primary mt-2 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                  <div className="clearfix" />
                  <div className="mt-3 mb-3">
                    <hr className="mt-1" />{" "}
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </div>
            {/* Right Side */}
            <div className="col-lg-4 sidebar">
              <Samples_widget />
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Blogs
